<template>
  <v-icon :color="color">{{ icon }}</v-icon>
</template>

<script>
export default {
  components: {},
  props: {
    febitda: Number
  },
  data() {
    return {
      values: {
        0: {
          icon: "mdi-minus",
          color: "danger"
        },
        1: {
          icon: "mdi-arrow-down",
          color: "danger"
        },
        2: {
          icon: "mdi-arrow-up",
          color: "success"
        },
        3: {
          icon: "mdi-plus",
          color: "success"
        }
      }
    };
  },
  computed: {
    icon() {
      return this.values[this.febitda].icon;
    },
    color() {
      return this.values[this.febitda].color;
    }
  }
};
</script>

<style scoped>
.v-icon {
  font-size: 16px;
}
</style>
