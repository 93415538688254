<template>
  <v-row>
    <v-col cols="12">
      <stats-page-header />
    </v-col>

    <v-col cols="12" class="mb-0 py-0">
      <v-row class="mt-0 pt-0">
        <v-col class="mt-0 mb-0 pt-0 pb-0" sm="6">
          <h6 class="text-caption mt-2 text-uppercase text--disabled">{{ t("detailed_statistics_by_files") }}</h6>
        </v-col>
        <v-col class="text-right mt-0 mb-0 pt-0 pb-2" sm="6">
          <v-chip color="white" class="mr-2" @click="refreshData"
            >{{ $t("common.refresh") }}
            <v-icon right color="blue light" :size="16">
              mdi-refresh
            </v-icon></v-chip
          >

          <v-chip outlined class="blue" style="background: white !important;" @click="filtersVisible = !filtersVisible"
            >{{ $t("common.filters") }}
            <v-icon right color="blue light" :size="16">
              mdi-filter-outline
            </v-icon></v-chip
          >
        </v-col>
      </v-row>

      <v-card v-if="filtersVisible" flat>
        <div class="d-flex mb-0 pb-0">
          <div class="pt-6 ml-5 caption pb-0 mb-0 pr-2">
            {{ t("folder") }}
          </div>

          <div class="mt-0 mb-0 pt-0 pb-0">
            <v-select
              v-model="filters.folder_id"
              :items="folderSelectOptions"
              dense
              outlined
              class="ml-1 pb-0 mb-0 mt-3"
              @change="loadData"
            >
            </v-select>
          </div>

          <div class="mt-0 mb-0 pt-0 pb-0 flex-grow-1 flex-shrink-0">
            <v-text-field
              v-model="filters.fileName"
              outlined
              dense
              class="mt-3 ml-3"
              :label="t('find_by_name')"
              @keyup.enter="loadData"
            />
          </div>

          <div class="mt-0 mb-0 pt-0 pb-0 pr-3">
            <v-switch v-model="showHints" :label="t('show_hints')" class="ml-5"></v-switch>
          </div>
        </div>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-card>
        <v-data-table
          :loading="loadingData"
          :headers="dataTable.headers"
          :items="items"
          :server-items-length="totalItems"
          :sort-by.sync="sort.by"
          :sort-desc.sync="sort.desc"
          :items-per-page="itemsPerPage"
          :footer-props="dataTable.footerProps"
          @update:sort-desc="onUpdateSortDesc"
          @update:items-per-page="onUpdateItemsPerPage"
          @update:page="onPageUpdate"
        >
          <template #item.name="{item}">
            <a :href="item.file_url" :title="item.folder_name ? item.folder_name : t('inbox')">{{ item.name }}</a>
          </template>

          <template #item.created_date="{item}">
            <span :title="item.created_datetime">{{ item.created_date }}</span>
          </template>

          <template #item.money_today="{item}"> ${{ item.money_today }} </template>
          <template #item.money_total="{item}"> ${{ item.money_total }} </template>

          <template #item.febitda="{item}">
            <efficiency :febitda="item.febitda" />
          </template>
        </v-data-table>
      </v-card>

      <hints v-if="showHints" />
    </v-col>
  </v-row>
</template>

<script>
import StatsPageHeader from "../StatsPageHeader";
import Hints from "./Hints";
import Efficiency from "./Efficiency";

export default {
  components: { Efficiency, Hints, StatsPageHeader },
  metaInfo() {
    return {
      title: this.$t("titles.stats_files")
    };
  },
  data() {
    return {
      loadingData: false,
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      items: [],
      sort: {
        by: [],
        desc: []
      },
      filtersVisible: false,
      filters: {
        fileName: null,
        folder_id: null
      },
      showHints: false,
      folders: []
    };
  },
  computed: {
    apptype() {
      return this.$route.params.app;
    },

    dataTable() {
      return {
        headers: [
          {
            text: this.t("data_table.fields.name"),
            value: "name",
            sortable: true
          },
          {
            text: this.t("data_table.fields.size"),
            value: "size",
            sortable: true
          },
          {
            text: this.t("data_table.fields.created_date"),
            value: "created_date",
            sortable: true
          },
          {
            text: this.t("data_table.fields.download_count_today"),
            value: "download_count_today",
            sortable: true
          },
          {
            text: this.t("data_table.fields.download_count_total"),
            value: "download_count_total",
            sortable: true
          },
          {
            text: this.t("data_table.fields.video_view_count_today"),
            value: "video_view_count_today",
            sortable: false
          },
          {
            text: this.t("data_table.fields.video_view_count_total"),
            value: "video_view_count_total",
            sortable: false
          },
          {
            text: this.t("data_table.fields.premium_count_today"),
            value: "premium_count_today",
            sortable: true
          },
          {
            text: this.t("data_table.fields.premium_count_total"),
            value: "premium_count_total",
            sortable: true
          },
          {
            text: this.t("data_table.fields.money_today"),
            value: "money_today",
            sortable: true
          },
          {
            text: this.t("data_table.fields.money_total"),
            value: "money_total",
            sortable: true
          },
          {
            text: "",
            value: "febitda",
            sortable: false
          }
        ],
        footerProps: {
          itemsPerPageOptions: [5, 10, 25, 100]
        }
      };
    },

    fileMoneyTypeColors() {
      return {
        direct: "",
        rebill: "text-purple",
        copy: "text-gray-500"
      };
    },

    fileMoneyTypeHint() {
      return {
        direct: this.t("file_money_type.direct"),
        rebill: this.t("file_money_type.rebill"),
        copy: this.t("file_money_type.copy")
      };
    },

    statusColors() {
      return {
        hold: "primary",
        active: "success",
        refund: "warning",
        default: "info"
      };
    },

    datePickerMaxDate() {
      const now = new Date();

      return now.getFullYear() + "-" + (now.getMonth() + 1).toString().padStart(2, "0") + "-" + now.getDate();
    },

    folderSelectOptions() {
      let folders = this.folders.map(f => {
        return {
          value: f.id,
          text: " - ".repeat(f.level) + f.name
        };
      });

      folders.unshift({
        value: null,
        text: this.t("all")
      });

      return folders;
    }
  },
  watch: {
    $route() {
      this.loadData();
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loadingData = true;

      this.$store
        .dispatch("statistics/loadFilesData", {
          app: this.apptype,
          page: this.currentPage,
          per_page: this.itemsPerPage,
          sort_key: this.sort.by.length > 0 ? this.sort.by[0] : null,
          sort_dir: this.sort.desc.length > 0 ? (this.sort.desc[0] ? "desc" : "asc") : null,
          file_name: this.filters.fileName,
          folder_id: this.filters.folder_id
        })
        .then(response => {
          this.items = response.data.data;
          this.loadingData = false;
          this.itemsPerPage = response.data.meta.per_page;
          this.totalItems = response.data.meta.total;
          this.folders = response.data.folders;
        })
        .catch(() => {
          this.loadingData = false;

          this.$store.dispatch("app/showDialog", {
            type: "error",
            title: "Error",
            message: "Failed getting data"
          });
        });
    },
    refreshData() {
      if (this.loadingData) {
        return;
      }

      this.loadData();
    },
    onUpdateSortDesc() {
      if (this.loadingData) {
        return;
      }

      this.loadData();
    },
    onUpdateItemsPerPage(itemsPerPage) {
      if (this.loadingData) {
        return;
      }

      this.itemsPerPage = itemsPerPage;
      this.loadData();
    },
    onPageUpdate(page) {
      if (this.loadingData) {
        return;
      }

      this.currentPage = page;
      this.loadData();
    },
    holdReleaseDateFormatted(dateString) {
      const date = new Date(dateString);

      return date.getDate() + " " + this.$t("app.months.short." + (date.getMonth() + 1));
    },
    holdReleaseDateTitle(dateString) {
      const date = new Date(dateString);

      return (
        date.getDate() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
    },
    mapPaymentsSystemsToOptions(paymentSystems) {
      let options = [{ text: this.t("all"), value: null }];

      for (let key in paymentSystems) {
        options.push({
          text: paymentSystems[key],
          value: key
        });
      }

      return options;
    },
    getPaymentSystemLabel(paymentSystemAlias) {
      const result = this.paymentSystems.filter(s => s.value === paymentSystemAlias);

      if (result.length === 1) {
        return result[0].text;
      } else {
        return this.t("unknown");
      }
    },
    t(key, params) {
      return this.$t("pages.statistics.files." + key, params);
    }
  }
};
</script>
