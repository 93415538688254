<template>
  <div class="pt-3">
    <v-card>
      <v-card-text>
        <div class="subtitle-1">
          {{ t("hints") }}
        </div>

        <v-icon color="success">mdi-plus</v-icon>
        {{ t("hint_plus") }}
        <br />

        <v-icon color="success">mdi-arrow-up</v-icon>
        {{ t("hint_arrow_up") }}
        <br />

        <v-icon color="danger">mdi-arrow-down</v-icon>
        {{ t("hint_arrow_down") }}
        <br />

        <v-icon color="danger">mdi-minus</v-icon>
        {{ t("hint_minus") }}
        <br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  methods: {
    t(key, params) {
      return this.$t("pages.statistics.files." + key, params);
    }
  }
};
</script>

<style scoped>
.v-icon {
  font-size: 16px;
}
</style>
